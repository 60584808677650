import { setShippingSourcesForProduct } from "../../analytics/pageLoad";
import PropTypes from "prop-types";
import styles from "./DeliveryAndReturns.scss";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import {
  allVariantsPrimarySource,
  getInStockVariants,
} from "../../../utils/variantUtils";
import SoldShippedHeader from "./soldShippedHeader/SoldShippedHeader";
import { getProductSourceSellerInfo } from "../../../utils/sourceSellerHelper";
import { useVariant } from "../../context/variant/VariantContext";
import { useProduct } from "../../context/product/ProductContext";
import { fireAfsAnalytics } from "../../analytics/pageInteraction";

const classnames = classnamesBind.bind(styles);

function fireSourcingAnalytics({
  selectedVariant,
  product,
  sourceSellerInfo,
  selectedOrAvailableVariants,
}) {
  if (!selectedVariant) {
    setShippingSourcesForProduct({
      fulfillerId: allVariantsPrimarySource(product.variants)
        ? "primary"
        : sourceSellerInfo.sourceId?.toLowerCase(),
      sellerId: sourceSellerInfo.sellerId,
      hasShippedByLabel: !!(
        sourceSellerInfo.sellerLabel || sourceSellerInfo.sourceLabel
      ),
      productId: product.id,
      variants: selectedOrAvailableVariants,
    });
  }
}

const getSelectedOrAvailableVariants = ({ selectedVariant, product }) => {
  if (selectedVariant) {
    return [selectedVariant];
  }

  if (product) {
    return getInStockVariants(product.variants);
  }
};

export default function DeliveryAndReturnsReact({
  toggleShippingRestrictions,
}) {
  const {
    getTranslation,
    countryName,
    deliveryApiEndpoint,
    features: { showReturnsMessage },
    deliveryAndReturnsTrackingValue,
  } = useRuntimeConstants();
  const { product } = useProduct();
  const { selectedVariant } = useVariant();
  const selectedOrAvailableVariants = getSelectedOrAvailableVariants({
    selectedVariant,
    product,
  });
  const sourceSellerInfo = getProductSourceSellerInfo({
    variants: selectedOrAvailableVariants,
  });
  const deliveryAndReturnsLink = `${deliveryApiEndpoint}${
    deliveryAndReturnsTrackingValue
      ? `#feemsg=${encodeURIComponent(deliveryAndReturnsTrackingValue)}`
      : ""
  }`;

  const openShippingRestrictionsModal = (event) => {
    toggleShippingRestrictions(
      true,
      product.shippingRestrictions?.shippingRestrictionsIncludedCountries,
      event.target
    );
  };

  const renderFreeDelivery = () => (
    <div
      data-testid="deliveryAndReturns__freeDelivery"
      className={classnames(styles.row, styles.freeDelivery)}
      aria-live="polite"
    >
      <span className={styles.iconContainer}>
        <span className={`${styles.icon} product-delivery-van`} />
      </span>
      {sourceSellerInfo.isConsistent
        ? `${getTranslation(
            "delivery_information_threshold_message_qualifying_orders1"
          )}.`
        : getTranslation(
            "delivery_information_threshold_message_no_variant_selected"
          )}
    </div>
  );

  const renderReturnsLabel = () =>
    showReturnsMessage && (
      <div
        data-testid="deliveryAndReturns__returnsLabel"
        className={styles.row}
      >
        <span className={styles.iconContainer}>
          <span className={classnames(styles.icon, "product-returns-box")} />
        </span>
        {getTranslation(
          sourceSellerInfo.isPartnerFulfils
            ? "returns_message_partner"
            : "returns_message_asos"
        )}
      </div>
    );

  const renderTerms = () => (
    <div
      data-testid="deliveryAndReturns__terms"
      className={classnames(styles.row, styles.noIcon, styles.terms)}
    >
      <span>
        <a
          data-testid="deliveryAndReturns__termsLink"
          href={deliveryAndReturnsLink}
          target="_blank"
          aria-label={`${getTranslation(
            "delivery_and_returns_policy"
          )}. ${getTranslation("generic_opens_new_window")}.`}
          rel="noreferrer"
        >
          <span className={styles.termsLinkText} aria-hidden={true}>
            {getTranslation("delivery_and_returns_policy")}
            <span className={`${styles.icon} product-new-page-small`} />
          </span>
        </a>
      </span>
    </div>
  );

  const renderShippingRestrictions = () => {
    if (
      !!sourceSellerInfo.sellerLabel &&
      product.shippingRestrictions.shippingRestrictionsForSellers
    ) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <span>
            {`${sourceSellerInfo.sellerLabel} ${getTranslation(
              "dtc_shipping_restriction_label"
            )} ${countryName}`}
          </span>
        </div>
      );
    } else if (product.shippingRestrictions.shippingRestrictionsVisible) {
      return (
        <div
          data-testid="deliveryAndReturns__shippingRestrictions"
          className={classnames(styles.row, styles.shippingRestrictions)}
          aria-live="polite"
        >
          <button
            data-testid="deliveryAndReturns__shippingRestrictionsButton"
            onClick={openShippingRestrictionsModal}
          >
            {product.shippingRestrictions.shippingRestrictionsLabel}
          </button>
        </div>
      );
    }
  };

  if (!product) {
    return null;
  }

  fireSourcingAnalytics({
    selectedVariant,
    product,
    sourceSellerInfo,
    selectedOrAvailableVariants,
  });

  return (
    <div
      className={styles.container}
      data-testid="deliveryAndReturns__container"
    >
      <div className={classnames(styles.row, styles.shippingLabel)}>
        <SoldShippedHeader fireSellerInfoAnalytics={fireAfsAnalytics} />
      </div>
      {renderFreeDelivery()}
      {renderReturnsLabel()}
      {renderTerms()}
      {renderShippingRestrictions()}
    </div>
  );
}

DeliveryAndReturnsReact.propTypes = {
  toggleShippingRestrictions: PropTypes.func.isRequired,
};
